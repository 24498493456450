// Scripts for the division landing page template
'use strict'

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../../../styles/src/pages/division-landing.scss';

/*
 * Component imports
 */
// Article
import '../components/general/article';
// Feature block
import '../components/general/feature-block';
// Feature row
import '../components/options/feature-row';
// Video playlist
import '../components/promotions/video-playlist';
// Link block
import '../components/sidebar/link-block';
// CTA promotion
import '../components/promotions/calls-to-action';
// Testimonial promotion
import '../components/promotions/testimonial';

/*
 * Page-specific scripting
 */
